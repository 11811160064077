body {float: left;width: 100%;box-sizing: border-box;overflow: hidden;position: relative;}
.wrapper {float: left;width: 100%;overflow: hidden;}
.wrapper_second {float: left;width: 100%;position: relative;}

.logincontainer {
max-width: 470px !important;
}
.loginlogo img {width: 120px;}
.navbar-vertical.navbar-expand-lg .navbar-nav .nav-link {
padding: 11px 14px !important;
border-radius: 7px;
font-size: 15px;
}
.login-body {
padding: 30px 30px !important;
}
.danger-colorset {
font-size: 12px;
color: #F84646;
float: left;
width: 100%;
margin: 4px 0 5px;
}
.dropdown-item {
cursor: pointer;
}
.card-header-right {
float: right;
width: auto;
}
.card-header-right select:first-child {
margin-right: 10px;
}
.card-header-right select {
box-shadow: none !important;
}
.card-header-New {
float: left;
margin: 0 0 15px;
width: 100%;
}
.card-table .userAvatar .previewImage {
width: 60px;
height: 60px;
object-fit: contain;
background-color: #f6f6f6;
border-radius: 6px;
}
.card-table-new {
float: left;
width: 100%;
height: calc(100vh - 190px);
overflow: overlay;
}
.activestatusselet, .userLimitselect {
float: left;
width: 90px;
border: 1px solid #ddd !important;
background-image: url("../img/select-arrow.png");
background-size: 13px;
background-repeat: no-repeat;
background-position: 91% center;
cursor: pointer;
}
.navbar-vertical.navbar-expand-lg.navbar-dark .navbar-nav .nav-link:hover ,.navbar-vertical.navbar-expand-lg.navbar-dark .navbar-nav .nav-link.active {
background-color: #243049;
}
.navbar-vertical.navbar-expand-lg.navbar-light .navbar-nav .nav-link:hover ,.navbar-vertical.navbar-expand-lg.navbar-light .navbar-nav .nav-link.active {
background-color: #eee;
}
.navbar-vertical.navbar-expand-lg.navbar-dark .navbar-nav .nav-link.active .nav-link-icon, .navbar-vertical.navbar-expand-lg.navbar-dark .navbar-nav .nav-link:hover .nav-link-icon  {
color: #fff;
}
#focusLabel {
    float: left;
    width: auto;
    background-color: #333;
    color: #fff;
    padding: 5px 11px;
    font-size: 13px;
    position: absolute;
    left: 0;
    top: -32px;
    border-radius: 8px;
}
.productinfo {
    margin: 0 0 0px 10px;
}
.navbar-vertical.navbar-expand-lg {
width: 18rem;
}
.card-header {
margin-bottom: 15px;
justify-content: space-between;
}
.commn-table-set {
float: left;
width: 100%;
}
.navbar-expand-lg.navbar-vertical~.navbar, .navbar-expand-lg.navbar-vertical~.page-wrapper {
margin-left: 18rem;
}
.navbar-nav .nav-item {
padding: 5px 10px 5px 10px;
}
.page-wrapper {
padding: 20px;
background-color: #eee;
height: 100vh;
overflow: auto;
}
.page-mainset {
float: left;
width: 100%;
position: relative;
}
.sashboardstatuscountswrapper {
float: left;
width: 100%;
position: relative;
display: flex;
flex-flow: wrap;
column-count: 4;
column-gap: 12px;
justify-content: flex-start;
}
.page-wrapper .page-header .col {
margin: 0px;
padding: 0px;
}
.page-wrapper .page-header  {
margin: 0px;
min-height: auto;
background-color: #fff;
padding: 10px 18px;
border-radius: 7px;
position: relative;
flex-direction: row;
margin-bottom: 15px;
}
.page-wrapper .page-header .page-title {
font-size: 17px;
}
.profiledropdown .nav-link {
background: transparent !important;
border: 0px;
box-shadow: none;
cursor: pointer;
}
.profiledropdown .nav-link img {
width: 30px;
height: 30px;
object-fit: cover;
object-position: center;
border-radius: 100%;
overflow: hidden;
}
.navbar-vertical.navbar-expand-lg .navbar-brand .navbar-brand-image {
width: 100%;
height: 105px;
object-fit: contain;
}
.card-body {
float: left;
width: 100%;
background-color: #fff;
padding: 17px 18px;
border-radius: 7px;
margin-bottom: 15px;
}
.persentageset {
display: flex;
align-items: center;
justify-content: flex-end;
width: auto;
}
.card-table thead tr th {
background-color: #eee;
color: #333;
font-size: 12px;
padding: 13px 10px;
text-transform: capitalize;
}
.card-table thead tr td {
font-size: 12px;
padding: 13px 10px; 
}
.card-table table  {
border: 1px solid #eee;
}
.table-image {
width: 46px;
height: 46px;
object-fit: cover;
object-position: center;
border-radius: 6px;
position: relative;
}
.btntable {
padding: 0px;
width: 16px;
margin: 0 7px;
border: 0px;
box-shadow: none;
}
.searchBoxwithbtn {
float: left;
width: 260px;
position: relative;
}
.searchBoxwithbtn .form-control {
border: 1px solid #ddd;
box-shadow: none !important;
padding: 7px 35px 7px 10px;
border-radius: 6px;
height: 36px;
font-size: 13px;
}
.searchBoxwithbtn .icon-tabler-search {
position: absolute;
top: 8px;
right: 9px;
}
.searchBoxwithbtn .searchclear {
width: 13px;
height: 13px;
position: absolute;
top: 11px;
right: 9px;
cursor: pointer;
}
.addbtnset {
padding: 8px 12px;
border: 0px;
margin: 0px 0px 0px 11px;
border-radius: 6px;
background-color: #243049;
color: #fff;
}
.addbtnset svg {
margin: 0 5px 0 0px;
}

/* common modal start here */
.ModalBackground {
position: fixed;
left: 0;
top: 0;
z-index: 999999;
height: 100%;
width: 100%;
background: rgba(0,0,0,0.4);
}
.CommonPopupWrapper {
float: left;
width: 500px;
position: fixed;
left: 50%;
top: 50%;
transform: translate(-50%, -50%);
background-color: #fff;
z-index: 999999;
border-radius: 10px;
}
.CommonPopupWrapper .commpopupheader {
float: left;
width: 100%;
padding: 12px 20px;
position: relative;
display: flex;
justify-content: space-between;
border-bottom: 1px solid #ececec;
}
.CommonPopupWrapper .commpopupheader h4 {
margin: 0px;
font-size: 20px;
float: left;
width: auto;
}
.CommonPopupWrapper .commpopupheader .closebtn {
position: absolute;
right: 10px;
top: 12px;
cursor: pointer;
}
.form-Image-Upload .danger-colorset {
position: absolute;
width: 150px;
top: 30px;
transform: translateX(65%);
}
.form-Image-Upload .danger-colorset {
position: absolute;
width: 150px;
top: 30px;
transform: translateX(65%);
}
.uploadfileCustombtn {
display: flex;
border: 1px solid #e6e7e9;
border-radius: 6px;
position: relative;
height: 39px;
justify-content: flex-start;
align-items: center;
padding: 10px 10px;
font-size: 13px !important;
color: #3e3e3e;
font-weight: 400 !important;
cursor: pointer;
}
.uploadfileCustombtn input.form-control {
visibility: hidden;
position: absolute;
left: 0;
top: 0;
width: 100%;
height: 100%;
}
.uploadfileCustombtn svg {
width: 19px;
margin: 0px 5px 0 0;
}
.uploadfileCustombtn.active {
color: #2aa13d;
border-color: #2aa13d;
background: rgb(195 255 204 / 10%);
font-weight: 500 !important;
}
.filuplodername {
line-height: normal;
display: flex;
float: left;
width: 100%;
align-items: center;
justify-content: flex-start;
}
.card-table .previewImage {
float: left;
width: 80px;
height: 80px;
position: relative;
}
.card-table .previewImage img {
float: left;
width: 100%;
height: 100%;
object-fit: contain;
object-position: center;
}
.card-table tbody tr td {
font-size: 13px;
}
.productcarttbale {
display: block;
padding: 0px;
}
.order-managment-table td {
vertical-align: top;
}
.productcarttbale li {
display: flex;
margin-bottom: 10px;
align-items: center;
}
.productcarttbale li .productinfo p {
margin: 0px;
font-size: 12px;
cursor: pointer;
}
.productcarttbale li .productinfo p svg {
width: 18px;
height: 18px;
margin: 0 3px 0px 0px;
}
.productcarttbale li:last-child {
margin-bottom: 0px;
}
.productcarttbale li .previewImage {
width: 50px;
height: 55px;
}
.productcarttbale li .previewImage img {
object-position: left;
}
.productcarttbale li .productinfo h4 {
font-size: 13px;
line-height: normal;
margin: 0 0 5px 0px;
}
.filuplodername svg {
width: 17px;
line-height: normal;
height: 17px;
margin: 2px 0 0 3px;
color: red;
cursor: pointer;
}
.Toastify__toast-container {
z-index: 9999999 !important;
}
.filedsearchReltive .searchWrapper {
border: 1px solid #e6e7e9;
border-radius: 6px;
position: relative;
height: auto !important;
padding: 5px 10px;
}
.multiSelectContainer ul {
max-height: 160px !important;
overflow-y: overlay !important;
}
.multiSelectContainer ul li {
list-style: none;
padding: 6px 15px;
font-size: 13px;
cursor: pointer;
border-bottom: 1px solid #eee;
}
.filedsearchReltive .searchWrapper #search_input {
height: auto;
}
.CommonPopupWrapper .commonpopupbody {
float: left;
width: 100%;
padding: 12px 20px;
position: relative;
border-bottom: 1px solid #ececec;
max-height: calc(100vh - 220px);
overflow: overlay;
}
.filedsearchReltive {float: left;width: 100%;position: relative;}
.filedsearchReltive .searchDropdownlist {
    max-height: 160px;
}
.CommonPopupWrapper .commnfooter {
float: left;
width: 100%;
padding: 12px 20px;
position: relative;
display: flex;
justify-content: flex-end;
}
.form-Image-Upload {
float: none;
width: 90px;
margin: 0 auto 15px;
height: 90px;
position: relative;
border-radius: 100%;
border: 1px solid #ddd;
}
.form-group {
float: left;
width: 100%;
margin: 0 0 10px;
}
.form-group label {
float: left;
width: 100%;
margin-bottom: 6px;
font-weight: 500;
font-size: 13px;
}
.form-group input {
box-shadow: none !important;
height: 39px;
font-size: 13px;
}
.form-group textarea {
box-shadow: none !important;
height: 100px;
font-size: 13px;
resize: none;
}
.form-Image-Upload img {
height: 100%;
width: 100%;
border-radius: 100%;
object-fit: cover;
object-position: center;
}
.form-Image-Upload .fileuplodbtn {
width: 24px;
height: 24px;
border-radius: 100%;
overflow: hidden;
position: absolute;
right: 10px;
top: 3px;
cursor: pointer;
background-color: #243049;
display: flex;
justify-content: center;
padding: 0px;
text-align: center;
align-items: center;
}
.form-Image-Upload .fileuplodbtn input[type="file"] {
visibility: hidden;
cursor: pointer;
position: absolute;
width: 0;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
-webkit-appearance: none;
margin: 0;
}
input[type=number] {
-moz-appearance: textfield;
}
input[type="password"]::-ms-reveal,
input[type="password"]::-ms-clear {
    display: none;
}
.form-Image-Upload .fileuplodbtn svg {
fill: #fff;
width: 20px;
height: 20px;
}
label.form-check.form-switch {
    width: auto;
}
.form-Image-Upload .fileuplodbtnss {
width: 24px;
height: 24px;
border-radius: 100%;
overflow: hidden;
position: absolute;
right: 10px;
top: 3px;
cursor: pointer;
background-color: #ff1e10;
display: flex;
justify-content: center;
text-align: center;
align-items: center;
color: #fff;
}
.form-Image-Upload .fileuplodbtnss svg {
fill: #fff;
width: 18px;
height: 18px;
}
.successbtn {
padding: 8px 19px;
border: 1px solid #206bc4;
margin: 0px 0px 0px 11px;
border-radius: 6px;
background-color: #206bc4;
color: #fff;
font-size: 13px;
font-weight: 400;
letter-spacing: 0.4px;
}
.successbtn:hover {
border: 1px solid #243049;
background-color: #243049;
color: #fff;
}
.cancelbtn {
padding: 8px 19px;
border: 0px;
margin: 0px 0px 0px 11px;
border-radius: 6px;
border: 1px solid #243049;
font-size: 13px;
font-weight: 400;
letter-spacing: 0.4px;
}
.CommonPopupWrapper .commonpopupbody h4 {
font-size: 16px;
line-height: 27px;
font-weight: 400;
}
.logincontainer .input-group .input-group-text .link-secondary {
    cursor: pointer;
}
.logincontainer .form-control, .logincontainer .input-group {
    height: 44px;
    outline: none !important;
    box-shadow: none !important;
}
.logincontainer .form-footer .btn {
    padding: 10px 10px;
}
.form-check, .categorytbale {
margin-bottom: 0px;
}
.CommonPopupWrapper .commonpopupbody h4 .usertomodal {
float: left;
width: 100%;
text-align: center;
font-weight: 500;
}
.CommonPopupWrapper.CommonPopupDelete {
width: 390px;
text-align: center;
}
.CommonPopupWrapper.CommonPopupDelete .commpopupheader h4 {
float: left;
width: 100%;
}
.CommonPopupWrapper.CommonPopupDelete .commonpopupbody {
padding: 20px 20px;
}
.CommonPopupWrapper.CommonPopupDelete .commonpopupbody .commondeleteicon {
width: 65px;
margin: 0 0 15px;
}
.CommonPopupWrapper.CommonPopupDelete .commnfooter {
justify-content: center;
}
/* common modal end here */


.paginationCustom {float: left;width: 100%;padding: 0px;margin: 15px 0px 0px;}
.paginationCustom li:first-child, .paginationCustom li:last-child {display: none;}
.paginationCustom li .rpb-item--active {background-color: #014087;}
.paginationCustom li button {width: 29px;height: 29px;min-width: 29px;font-size: 14px;}
.searchDropdownlist {
float: left;
width: 100%;
position: absolute;
left: 0;
top: 40px;
background-color: #fff;
z-index: 999;
box-shadow: 0px 5px 17px -2px #ccc;
border-radius: 6px;
max-height: 250px;
padding: 0px;
overflow: overlay;
}
.searchDropdownlist li {
list-style: none;
padding: 6px 15px;
font-size: 13px;
cursor: pointer;
border-bottom: 1px solid #eee;
}
.searchDropdownlist li:hover {
    background-color: #eee;
}

/* country code start here */
.selectNoInputField {float: left;width: 100%;border: 1px solid #E0E0E5;border-radius: 7px;position: relative;display: flex;}
.selectNoInputField input {height: 48px;border: 0px;padding: 10px 10px;font-size: 13px;outline: none;width: 100%;}
.selectNoInputField .inputBoxSelect {width: 100px;text-align: left;margin: 0px;position: relative;padding-left: 16px;}
.selectNoInputField .inputBoxSelect::after{content: "";float: left;width: 1px;height: 25px;background-color: #c1c1c1;position: absolute;right: 0;top: 12px;}
.form-group.error .selectNoInputField {border-color: #F84646;}
.form-group.error .form-groupfiled.expirydatswrapper .SiXSY, .form-group.error .form-groupfiled.expirydatswrapper .iqDxdu {border-color: #F84646 !important;}
.countselet {font-size: 12px;float: left;width: auto;margin: 0 1px 0 0;}
.flagsDropdown {
float: left;
width: 220px;
margin: 0px;
padding: 0px;
position: absolute;
z-index: 9;
background-color: #fff;
box-shadow: 1px 2px 10px rgb(0 0 0 / 35%);
left: 0;
bottom: 51px;
top: auto;
display: none;
}
.flagsDropdown.active {
display: block;
}
.flagsDropdown ul {
float: left;
width: 100%;
padding: 0px;
margin: 0px;
max-height: 200px;
overflow: auto;
}
.flagsDropdown li {
float: left;
width: 100%;
list-style: none;
display: flex;
padding: 10px 13px;
font-size: 12px;
cursor: pointer;
text-align: left;
vertical-align: middle;
align-items: center;
font-family: Avenir Next,Helvetica Neue,Helvetica,Arial;
}
.flagsDropdown li:hover {
background-color: #eee;
}
.flagsDropdown li img {
width: 26px;
margin: 0px 8px 0px 0px;
}
.selectNoInputField .inputBoxSelect .selectEmojis {
padding: 1px;
border: 0px;
height: 100%;
width: 100%;
display: flex;
position: relative;
align-items: center;
text-align: center;
justify-content: flex-start;
background-color: transparent;
margin-right: 5px;
font-family: Avenir Next,Helvetica Neue,Helvetica,Arial;
}
.selectNoInputField .inputBoxSelect .selectEmojis .flagsimg {
width: 20px;
height: 20px;
border-radius: 100%;
margin: 0px 7px 0px 0px;
object-fit: cover;
}
.emailset {
font-family: "Avenir-LT-W01_85-Heavy1475544";
}
.noteemail {
font-family: "Avenir-LT-W01_85-Heavy1475544";
color: #9c9c9c;
font-size: 15px;
}
.selectNoInputField .inputBoxSelect .selectEmojis .arrowdownimg {
width: 7px;
margin: 0px 0px 0px 3px;
}
.btn.formcomnbtn {
background-color: #1D1D1D;
border-color: #1D1D1D;
width: 100%;
padding: 11px 45px;
float: right;
font-size: 16px;
color: #cecece;
border-radius: 0;
}
/* country code end here */

@keyframes blink {50% { color: transparent }}
.uploadingdots .loader__dot { animation: 1s blink infinite; margin-right: 2px;}
.uploadingdots:nth-child(2) { animation-delay: 250ms }
.uploadingdots:nth-child(3) { animation-delay: 500ms }

.sidebar-container .closebtn{
    display: none;
 }
 
 .header-toggle {
      display: none;
 }

 /* Loader start here */
.loaderwrapper {
    float: left;
    width: 100%;
    height: 100%;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 99999999;
    background: rgba(255,255,255, 0.4);
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    backdrop-filter: blur(5px);
}
.btn-reply {
    border: transparent;
    background: #014087;
    color: #ffff;
    border-radius: 5px;
    padding: 7px;
}
.loaderwrapper .loader {
    border: 5px solid #070000;
    border-radius: 50%;
    border-top: 5px solid transparent;
    width: 100px;
    height: 100px;
    animation: spin 0.9s linear infinite;
}
.loaderwrapper h4 {
    margin: 20px 0px 0px;
}
.addusebtn, .addusebtn:focus {
background-color: rgba(43,7,77) !important;
color: #fff !important;
margin-left: 10px;
border-color: rgba(43,7,77) !important;
}
.addusebtn:hover {
color: #fff;
background-color: rgb(131 0 255) !important;
border-color: rgb(131 0 255) !important;  
}
.passwordform {
float: left;
width: 100%;
position: relative;
}
.passwordform .showpassworbtn {
float: left;
height: 100%;
background-color: transparent !important;
box-shadow: none !important;
outline: none !important;
border: 0px;
display: flex;
justify-content: center;
text-align: center;
align-items: center;
padding: 7px 11px;
position: absolute;
right: 0px;
top: 0px;
}
.switchbutton {
float: left;
width: 100%;
display: flex;
}
.switchbutton .form-group {
width: 50%;
margin-right: 20px;
}
.passwordform .showpassworbtn svg {
margin: 0px;
}
/* Safari */
@-webkit-keyframes spin {
0% { -webkit-transform: rotate(0deg); }
100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
0% { transform: rotate(0deg); }
100% { transform: rotate(360deg); }
}
/* Loader end here */

/* firefox css start here */
@supports (-moz-appearance:none) {
    .card-table-new {
        overflow: auto;
        scrollbar-color: #b9b9b9 transparent;
        scrollbar-width: thin;
    }
    .table-responsive {
        overflow: auto;
        scrollbar-color: #b9b9b9 transparent;
        scrollbar-width: thin;
    }
    .CommonPopupWrapper .commonpopupbody {
        overflow: auto;
        scrollbar-color: #b9b9b9 transparent;
        scrollbar-width: thin;
    }
}
.columnssewrapper:hover {
border: 2px solid #333;
}
.columnssewrapper {
text-align: center;
position: relative;
flex-basis: 30.33%;
border: 1px solid #d7d7d7;
padding: 5px;
border-radius: 10px;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
height: 70px;
width: 30.33%;
}
#columnssewrapperid.columnssewrapper .h1.mb-3 {
margin-bottom: 0px !important;
}
.columnssewrapper .subheader {
font-weight: 600;
font-size: 10px;
margin-bottom: 2px;
float: left;
width: 100%;
color: #fff;
}
.columnssewrapper .h1.mb-3 {margin-bottom: 5px !important;}
.dashtitle {
float: left;
width: 100%;
font-size: 17px;
color: #333;
margin: 0 0 11px;
font-weight: 600;
}
.columnflex {
float: left;
width: 100%;
display: flex;
justify-content: space-between;
position: relative;
}
.columnssewrapper .h1{
font-size: 18px;
float: left;
width: 100%;
text-emphasis: revert;
display: inline-block;
white-space: nowrap;
overflow: hidden !important;
text-overflow: ellipsis;
}
.columnflex .columnssewrapper:first-child {
    color: #fff;
    background-image: linear-gradient(230deg,#759bff,#843cf6);
}
.columnflex .columnssewrapper:nth-child(2) {
    color: #fff;
    background-image: linear-gradient(230deg,#a2cf6e,#4caf50);
}
.columnflex .columnssewrapper:nth-child(3) {
    color: #fff;
    background-image: linear-gradient(230deg,#ffc480,#ff763b);
}

.passwordnotewrapper {
float: right;
width: auto;
position: relative;
}
.passwordnotewrapper img {
    width: 14px;
}
.passwordnotewrapper ol li {
font-size: 11px;
}
.passwordnotewrapper ol {
float: left;
width: 200px;
background-color: #fff;
position: absolute;
right: 0;
z-index: 999;
bottom: 18px;
padding: 10px 10px 10px 25px;
margin: 0px;
box-shadow: 0px 0px 27px -6px #ccc;
border-radius: 9px;
display: none;
}
.passwordnotewrapper:hover ol {
display: block;
}

/* pagination css start here */
.paginationwraps {
    float: left;
    width: 100%;
    margin: 13px 0px 15px;
    font-weight: 500;
    display: flex;
    align-items: center;
    column-gap: 9px;
}
.paginationwraps .btn {
    width: 29px;
    height: 29px;
    min-width: 29px;
    font-size: 14px;
    background-color: #014087;
    color: #fff;
    text-align: center;
}
.paginationwraps .btn svg {
    margin: 0px;
}
.cuurentpagedetails {
    display: flex;
    align-items: center;
    column-gap: 5px;
}
.cuurentpagedetails select {
    height: 29px;
    padding: 2px 15px 2px 7px;
    box-shadow: none !important;
    background-image: url("../img/select-arrow.png");
    background-repeat: no-repeat;
    background-size: 10px;
    background-position: 90% center;
    cursor: pointer;
}
/* pagination css end here */


/* loader css start here */
.commn_loaderset {
float: left;
width: 100%;
height: 100%;
position: absolute;
left: 0;
top: 0;
display: flex;
justify-content: center;
align-items: center;
background-color: rgba(255,255,255,0.7);
column-gap: 14px;
}
.commn_loaderset h4 {
margin: 0px 0px 0px 15px;
font-size: 20px;
}
.commn_loaderset .loaderwrapper .loader {
border: 5px solid #333;
border-radius: 50%;
border-top: 5px solid transparent;
width: 45px;
height: 45px;
}
.commn_loaderset .loaderwrapper {
    display: flex;
    flex-direction: initial;
}

/* tooltip css start here */
.toolktis {
position: relative;
float: right;
width: auto;
}
.infostatus {
float: left;
width: auto;
}
.toolktis .erroMessagwrapper {
float: left;
width: auto;
margin: 0 0 0 5px;
}
.toolktis .erroMessagwrapper svg {
height: 19px;
width: 19px;
cursor: pointer;
}
.toolktis .tooltpimessae {
float: left;
width: 181px;
position: absolute;
top: -25px;
right: 24px;
background-color: #333;
color: #fff;
padding: 7px 15px;
font-size: 12px;
border-radius: 7px;
left: auto;
display: none;
}
.toolktis:hover .tooltpimessae {
display: block;
}
/* tooltip css end here */